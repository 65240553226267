
<script>
  // Extensions
  import View from '@/views/View'
  // Mixins
  import LoadSections from '@/mixins/load-sections'
  export default {
    name: 'Reward',
    metaInfo: { title: 'Validasi Produk | Website Resmi Fitsea' },
    extends: View,
    mixins: [
      LoadSections([
        'reward',
      ]),
    ],
    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
